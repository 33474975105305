import React ,{Fragment ,useState} from 'react'
import { CSVLink } from "react-csv";
import Filter from "./ResearchFilter";
import RadioBox from "./RadioBox";
import {connect} from "react-redux"
import { filtering } from "../../actions/research";



const FiltersResearchs = ({filtering,isAuthenticated,  user: { rolls },
    researchs: {researchs, filtered, lang },
}) => {
        
    const [myFilters, setMyFilters] = useState({
        filters: { Reviewer: "", thehub: "", Reviewer2: "",TypeOfParticipation :"",status3 : "" },
      });
      const [Abstra, setAbstra] = useState([
        { name: "الكل", value: "all" },
        { name: "المحور الأول ", value: "1" },
        { name: "المحور الثاني ", value: "2" },
        { name: "المحور الثالث ", value: "3" },
        { name: "المحور الرابع ", value: "4" },
        { name: "المحور الخامس ", value: "5" },
        { name: "المحور السادس ", value: "6" },
        { name: "المحور السابع ", value: "7" },
        { name: "المحور الثامن ", value: "8" },
        { name: "المحور التاسع ", value: "9" },
        { name: "المحور العاشر ", value: "10" },
      ]);
      const [typeOfParticipation , setTypeOfParticipation] =useState(
        [
          { name: "الكل", value: "all" },
          { name: "حضور ", value: "offline" },
          { name: "عن بعد", value: "online" },
        ]
      )
      const [Status3 , setStatus3] =useState(
        [
          { name: "الكل", value: "all" },
          { name: "المقبولة ", value: "accepted" },
          { name: "المقبولة بملاحظات", value: "acceptedwithnotes" },
          { name: "المرفوضة", value: "rejected" },
        ]
      )
      const [reviewer, setreviewer] = useState([
        { name: "الكل", value: "all" },
        { name: "نعم", value: "accepted" },
        { name: "لا", value: "no" },
      ]);
      const [reviewer2, setreviewer2] = useState([
        { name: "الكل", value: "all" },
        { name: "نعم", value: "accepted" },
        { name: "لا", value: "no" },
      ]);
        const handleFilters = (filters, filterBy) => {
        const newFilters = { ...myFilters };
        newFilters.filters[filterBy] = filters;
    
        // getFilteredProducts(skip, limit, newFilters);
        setMyFilters(newFilters);
        filtering(myFilters);
        console.log(myFilters);
      };
    
      // ************************
    
      let headers = [
        { label: " اسم الباحث الاول", key: "author" },
        { label: "عنوان البحث", key: "researchName" },
        { label: "الدرجة العلمية", key: "Degree" },
        { label: "المؤهل العلمي", key: "Qualification" },
        { label: "الجهة التابع لها", key: "Organization" },
        { label: "الدولة", key: "country" },
        { label: "المحور", key: "theHub" },
        { label: "رقم الهاتف للباحث الاول", key: "phone" },
        { label: " ايميل الباحث الاول", key: "email" },
        { label: " اسم الباحث الثاني", key: "author1" },
        { label: " ايميل الباحث الثاني", key: "email1" },
        { label: " اسم الباحث الثالث", key: "author2" },
        { label: " ايميل الباحث الثالث", key: "email2" },
        { label: "نوع المشاركة", key: "TypeOfParticipation" },

        { label: "اسم المراجع الاول", key: "Reviewer[name]" },
        { label: "درجة المراجع الاول", key: "status" },
        { label: " اسم المراحع التاني", key: "Reviewer2[name]" },
        { label: "درجة المراجع التاني", key: "status2" },
        { label: "الدرجة كاملة", key: "finalResualt" },

        { label: " حالة البحث", key: "status3" },
        { label: "المسؤول عن تغيير حالة البحث", key: "statusChange" },

        { label: "الملاحظات عن البحث", key: "acceptedNotes" },
        { label: "مكان و اسم الورقة ", key: "paper" },
        { label: "مكان و اسم الورقة المشفرة", key: "paperAfterEdit" },

      ];
    return (
        <>
         {isAuthenticated && (rolls === 2 || rolls === 1) && (
                  <Fragment>
                    {" "}
                    <h1>{lang === "ar" ? "بحث" : "Search"}</h1>
                    <div className="card bg-light">
                      <div className="mx">
                        <Filter />
                      </div>
                      <div style={filterStyle}>
                      <div className="card  bg-primary ">
                          <div className="clearfix ">
                            <br />
                            <h3 className="center-align">المحاور</h3>
                            <hr />

                            {/* //////////////////// */}
                            <div className="">
                              <RadioBox
                                title="abs"
                                abstra={Abstra}
                                handleFilters={(filters) =>
                                  handleFilters(filters, "thehub")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card  bg-primary ">
                          <div className="clearfix ">
                            <br />
                            <h3 className="center-align"> تم ارساله للمراجع الاول</h3>
                            <hr />

                            {/* //////////////////// */}
                            <div className="">
                              <RadioBox
                                title="rve"
                                abstra={reviewer}
                                handleFilters={(filters) =>
                                  handleFilters(filters, "Reviewer")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card  bg-primary ">
                          <div className="clearfix ">
                            <br />
                            <h3 className="center-align">تم ارساله للمراجع الثاني</h3>
                            <hr />

                            {/* //////////////////// */}
                            <div className="">
                              <RadioBox
                                title="ppr"
                                abstra={reviewer2}
                                handleFilters={(filters) =>
                                  handleFilters(filters, "Reviewer2")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card  bg-primary ">
                          <div className="clearfix ">
                            <br />
                            <h3 className="center-align"> نوع المشاركة</h3>
                            <hr />

                            {/* //////////////////// */}
                            <div className="">
                              <RadioBox
                                title="rve"
                                abstra={typeOfParticipation}
                                handleFilters={(filters) =>
                                  handleFilters(filters, "TypeOfParticipation")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card  bg-primary ">
                          <div className="clearfix ">
                            <br />
                            <h3 className="center-align">  حالة الورقة</h3>
                            <hr />

                            {/* //////////////////// */}
                            <div className="">
                              <RadioBox
                                title="rve"
                                abstra={Status3}
                                handleFilters={(filters) =>
                                  handleFilters(filters, "status3")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        
                        
                      </div>
                      {isAuthenticated && rolls === 2 && (
                        <CSVLink
                          className="btn btn-dark"
                          data={researchs}
                          headers={headers}
                          filename={"نتائج-البحث.csv"}
                        >
                          تحميل بيانات البحث
                        </CSVLink>
                      )}
                    </div>
                  </Fragment>
                )}
        </>
    )
}

const filterStyle = {
    direction: "rtl",
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridGap: "0.7rem",
    fontSize: "1.2rem",
  };
const mapStateToProps = state =>({
    isAuthenticated: state.auth.isAuthenticated,
    researchs: state.researchs,
    user : state.auth.user

})
export default connect(mapStateToProps ,{filtering})(FiltersResearchs)
